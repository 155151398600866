import React from "react";
import SafeHTML from "./safehtml";

const getMapUrl = (field) => {
  try {
      let address = field.json.content[0].content[0].value;
      address = address.replace(/\s|\n/g, '+');
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  } catch (e) {
      return "#";
  }  
}

export default function flMapLink(props) {
  if (!props)
    return null;
  return (
    <>
      <a href={getMapUrl(props)} target="_blank">
        <SafeHTML>{props}</SafeHTML>
      </a>
    </>
  );
}
