import React from "react";
import Img from "gatsby-image"
export default function flImage(props) {
  const getImageHTML = () => {
    if (!props.image.fluid) return null;
    let img = (
      <Img fluid={props.image.fluid} style={{height: '100%'}} className={props.className} alt={props.image.description} title={props.image.description} />
    );

    if (props.addImageAsBg)
      img = (
        <div className="img-cover" style={{ backgroundImage: "url(" + props.image.srcSet + ")" }} >{img}</div>
      );
    if (props.addWrapper) {
      img = (
        <section className="img-section">
          <div className="container">{img}</div>
        </section>
      );
    }
    return img;
  }
  return (
    getImageHTML()
  );
};
