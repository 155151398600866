import React from "react";
import SafeHTML from "../safehtml"
import { BlockTypes, BiLateralStyles } from "../../assets/js/utils"
import ImagesGallery from "../BlockList/imagesgallery"
import ButtonLink from "../flbuttonlink";
import MapLink from "../flmaplink";
import "./blockcontainer.scss";


function BilateralStyle1(props) {
    const { style, imagesOnLeft, block, DEVICE_VIEW } = props;
    const { title, description, cta, sections } = block;
    const { contact, detail } = extractSections(sections);
    const showInSingleRow = style === BlockTypes.BiLateralStyle1;

    return (
        <section key={props.key} className={`bilateral-section ${BiLateralStyles[style]}`}>
            <div className="container">
                <div className={`bilateral-inner row no-gutters ${imagesOnLeft ? "" : "flex-row-reverse"}`}>
                    <div className="col col-img">
                        <div className="bilateral-img">
                            <ImagesGallery {...props} />
                        </div>
                    </div>
                    <div className="col col-data">
                        <div className="bilateral-data">
                            {renderTitle(title)}
                            {renderContacts(contact, showInSingleRow, DEVICE_VIEW)}
                            {renderDescription(description)}
                            <div className="widget-row">
                                {detail && renderTextComponent(detail)}
                                {contact && contact.location && renderLocation(contact.location)}
                            </div>
                            {renderCta(cta)}
                            {contact && renderSocialLinks(contact)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

function BilateralStyle2(props) {
    const { style, imagesOnLeft, block, DEVICE_VIEW } = props;
    const { title, description, cta, sections } = block;
    const { contact, detail } = extractSections(sections);
    const showInSingleRow = style === BlockTypes.BiLateralStyle1;
    
    return (
        <section key={props.key} className={`bilateral-section ${BiLateralStyles[style]}`}>
            <div className="container">
                <div className={`bilateral-inner row no-gutters ${imagesOnLeft ? "" : "flex-row-reverse"}`}>
                    <div className="col col-img">
                        <div className="bilateral-img">
                            <ImagesGallery {...props} />
                        </div>
                    </div>
                    <div className="col col-data">
                        <div className="bilateral-data">
                            {renderTitle(title)}
                            {renderDescription(description)}
                            {detail && renderTextComponent(detail)}
                            {contact && contact.location && renderLocation(contact.location)}
                            {contact && renderContacts(contact, showInSingleRow, DEVICE_VIEW)}
                            {renderCta(cta)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

function Block(props) {
    const { style, title, sections, DEVICE_VIEW } = props;
    const { contact, detail } = extractSections(sections);
    const showInSingleRow = style === BlockTypes.BilateralStyle1;

    return (
        <section className="block-list">
            <div className="container">
                <div className="block-list-inner" key={props.key}>
                    <div className="row">
                        <div className="widget-col col-md-6 col-lg-3">
                            {renderTitle(title)}
                        </div>
                        <div className="widget-col col-md-6 hours-col">
                            {detail && renderTextComponent(detail)}
                        </div>
                        <div className="widget-col col-md-6 col-lg-3">
                            {contact && contact.location && renderLocation(contact.location)}
                        </div>
                        <div className="widget-col col-md-6 contact-col">
                            {contact && renderContacts(contact, showInSingleRow, DEVICE_VIEW)}
                            {contact && renderSocialLinks(contact)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const renderLocation = (location) => {
    return (
        <div className="bilateral-widget bilateral-location-info">
            <h6 className="widget-title">Location</h6>
            {location && <div className="widget-content text-pre">
                <SafeHTML>{location.title}</SafeHTML>
                <MapLink {...location.description} />
            </div>}
        </div>
    )
}
const renderPhone = (phone, DEVICE_VIEW) => {
    return <span className="link-item">
        {DEVICE_VIEW.IsMobile && <a href={`tel:"${phone}"`}>{phone}</a>}
        {!DEVICE_VIEW.IsMobile && <p>{phone}</p>}
    </span>;
}

const renderEmail = (email) => {
    return <span className="link-item email">
        <a href={`mailto:"${email}"`}>{email}</a>
    </span>;
}

const renderUrl = (urlCTA) => {
    return <span className="link-item url">
        <ButtonLink {...urlCTA} className="btn-link-item" />
    </span>;
}

const getContactComponents = (contact, DEVICE_VIEW) => {
    let contactComponents = [];
    contact.phone && contactComponents.push(renderPhone(contact.phone, DEVICE_VIEW));
    // contact.email && contactComponents.push(renderEmail(contact.email));
    contact.url && contactComponents.push(renderUrl(contact.url));
    return contactComponents;
}

const renderContacts = (contact, showInSingleRow, DEVICE_VIEW) => {
    const contactComponents = getContactComponents(contact, DEVICE_VIEW);
    return (
        <div className={`bilateral-widget bilateral-meta ${showInSingleRow ? "has-inline": ""}`}>
            {!showInSingleRow && <h6 className="widget-title">Contact</h6>}
            {contact && <div className="widget-content">
                {contactComponents.map((contactComponent, index) => {
                    return <div className="meta-info" key={index}>{contactComponent}</div>
                })}
            </div>}
        </div>
    )
}

const extractSections = (sections) => {
    let result = {
        "contact": null,
        "detail": null,
    }
    if (!sections) {
        return result;
    }
    sections.forEach(section => {
        if (section.__typename === 'ContentfulContact') {
            result.contact = section;
        } else if (section.__typename === 'ContentfulTextComponent') {
            result.detail = section;
        }
    });
    return result;
}

const renderTitle = (title) => {
    return (
        title && <h3 className="bilateral-title has-para">
            <SafeHTML>
                {title}
            </SafeHTML>
        </h3>
    )
}

const renderDescription = (description) => {
    return (
        description && <div className="bilateral-description">
            <SafeHTML>
                {description}
            </SafeHTML>
        </div>
    )
}

const renderTextComponent = (component) => {
    return <div className="bilateral-widget bilateral-content">
        {component.title && <h6 className="widget-title has-para"><SafeHTML>{component.title}</SafeHTML></h6>}
        {component.description && <div className="widget-content"><SafeHTML>{component.description}</SafeHTML></div>}
    </div>
}

const renderSocialLinks = (component) => {
    return (
        <div className="bilateral-socials">
            {component.instagramUrl && <a href={component.instagramUrl} className="vendor-social-link" rel="noopener noreferrer" target="_blank">
                <i className="icon icon-instagram"></i>
            </a>}
            {component.twitterUrl && <a href={component.twitterUrl} className="vendor-social-link" rel="noopener noreferrer" target="_blank">
                <i className="icon icon-twitter"></i>
            </a>}
            {component.facebookUrl && <a href={component.facebookUrl} className="vendor-social-link" rel="noopener noreferrer" target="_blank">
                <i className="icon icon-facebook"></i>
            </a>}
            {component.email && <a href={`mailto:"${component.email}"`} className="vendor-social-link" rel="noopener noreferrer" target="_blank">
                <i className="icon icon-email"></i>
            </a>}
        </div>
    )

}

const renderCta = (cta) => {
    return (
        cta && <div className="bilateral-action">
            <ButtonLink {...cta} />
            </div>
    )
}

export default function BlockContainer(props) {
    const BlockComponents = {
        [BlockTypes.BiLateralStyle1]: <BilateralStyle1 {...props}></BilateralStyle1>,
        [BlockTypes.BiLateralStyle2]: <BilateralStyle2 {...props}></BilateralStyle2>,
        [BlockTypes.ListingCard]: <Block {...props}></Block>
    }
    return (
        BlockComponents[props.style]
    );
};
