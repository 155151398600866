
export const FormFields = (props) => {
    return [
        {
            "type": "section-header",
            "value": "General Information",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "type",
            "label": "Type",
            "type": "select",
            "options": props.typeOptions,
            "placeholder": "- Type -",
            "validations": ["required"],
            "value": "",
            "styleClasses": ["col-md-12"],
            "handleClick": props.typeOnClick,
        },
        {
            "key": "name",
            "label": "Name",
            "type": "text",
            "placeholder": "Shop/Restaurant/Unwind/Event Name",
            "validations": ["required"],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "description",
            "label": "Description",
            "type": "textarea",
            "placeholder": "Shop/Restaurant/Unwind/Event Description",
            "validations": [],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "images",
            "label": "Images",
            "type": "file",
            "placeholder": "Images",
            "validations": [],
            "value": "",
            "multiple": true,
            "accept": "image/*",
            "styleClasses": ["col-md-12"]
        },
        {
            "key": "ctaText",
            "label": "Button Text",
            "type": "text",
            "placeholder": "e.g. Find A Table",
            "validations": [],
            "value": "",
            "styleClasses": ["col-md-6"],
        },
        {
            "key": "ctaUrl",
            "label": "Button URL",
            "type": "text",
            "placeholder": "e.g. http://website.com/reservations",
            "validations": ["url"],
            "value": "",
            "styleClasses": ["col-md-6"],
        },
        {
            "key": "detailsHeader",
            "label": "Additional Details Header",
            "type": "text",
            "placeholder": "e.g. Date & Time",
            "validations": [],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "detailsDescription",
            "label": "Additional Details Description",
            "type": "textarea",
            "placeholder": "e.g. February 1, 2020 | 8 PM",
            "validations": [],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
        {
            "type": "section-header",
            "value": "Contact Information",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "phone",
            "label": "Phone",
            "type": "text",
            "placeholder": "Phone",
            "validations": ["phone"],
            "value": "",
            "styleClasses": ["col-md-6"],
        },
        {
            "key": "email",
            "label": "Email",
            "type": "text",
            "placeholder": "Email",
            "validations": ["email"],
            "value": "",
            "styleClasses": ["col-md-6"],
        },
        {
            "key": "url",
            "label": "URL",
            "type": "text",
            "placeholder": "e.g. http://website.com",
            "validations": ["url"],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "address",
            "label": "Address",
            "type": "textarea",
            "placeholder": "Address",
            "validations": [],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "instagramUrl",
            "label": "Instagram URL",
            "type": "text",
            "placeholder": "Instagram URL",
            "validations": ["url"],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "twitterUrl",
            "label": "Twitter URL",
            "type": "text",
            "placeholder": "Twitter URL",
            "validations": ["url"],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
        {
            "key": "facebookUrl",
            "label": "Facebook URL",
            "type": "text",
            "placeholder": "Facebook URL",
            "validations": ["url"],
            "value": "",
            "styleClasses": ["col-md-12"],
        },
    ]
}
