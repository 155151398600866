/* eslint-disable react/no-danger */
import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from "react"
export default function SafeHTML({ children, className = '', el = 'span' }) {
  let props = {
    className,
  }

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        return <a href={node.data.uri} target='_blank' rel='noopener noreferrer'>{node.content[0].value}</a>;
      }
    }
  }
  
  if (children && children.json)
    return documentToReactComponents(children.json, options);

  if (typeof (children) === 'string') {
    props.dangerouslySetInnerHTML = { __html: children };
  } else {
    props.children = children;
  }
  return children ? React.createElement(
    el,
    props,
  ) : null;
}
