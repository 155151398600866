import { sendEmail } from './sendEmail';
import { getEmailTemplate } from './util/emailTemplateFormat'

export const sendEmailByTemplate = async (data) => {
    
    const payload = {
        emailTo : data.emailTo,
        emailFrom: 'submissions@thebreakers.com',
        emailSubject: 'Via Flagler Form Submission',
        emailTemplate: getEmailTemplate(data)
    }

    await sendEmail(payload);
}
