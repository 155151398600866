export const FormFields = (props) => {
  return [
    {
      key: 'firstname',
      value: '',
      label: 'First Name',
      type: 'text',
      placeholder: 'First Name',
      validations: ['required'],
      styleClasses: ['col-md-6'],
    },
    {
      key: 'lastname',
      value: '',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Last Name',
      validations: ['required'],
      styleClasses: ['col-md-6'],
    },
    {
      key: 'email',
      value: '',
      label: 'Email Address',
      type: 'text',
      placeholder: 'Email Address',
      validations: ['required', 'email'],
      styleClasses: ['col-md-6'],
    },
    {
      key: 'phone',
      value: '',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Phone Number',
      validations: ['required', 'phone'],
      styleClasses: ['col-md-6'],
    },
    {
      key: 'messagetopic',
      label: 'Message Topic',
      type: 'select',
      options: props.messageTopicOptions,
      placeholder: '- Message Topic -',
      validations: ['required'],
      value: '',
      styleClasses: ['col-md-12'],
      handleClick: props.messageTopicOnClick,
    },
    {
      key: 'message',
      value: '',
      label: 'Message',
      type: 'textarea',
      placeholder: 'Message Topic',
      validations: ['required'],
      styleClasses: ['col-md-12'],
    },
  ];
};
