const contentful = require('contentful-management')

const LOCALES = {
    "English (US)": "en-US",
}

export const createBlock = async (values) => {
    const spaceId = process.env.GATSBY_CONTENTFUL_SPACE_ID
    const environment = process.env.GATSBY_CONTENTFUL_ENVIRONMENT
    const managementToken = process.env.GATSBY_CONTENTFUL_MANAGEMENT_TOKEN
    
    const client = contentful.createClient({
        // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
        accessToken: managementToken
    })
    
    const SPACE = await client.getSpace(spaceId)
    const ENVIRONMENT = await SPACE.getEnvironment(environment);
    return await createBlockEntry(ENVIRONMENT, values);
}

const createBlockEntry = async (environment, values) => {
    const imageEntries = await createAssets(environment, values.images)
    const blockName = values.name;
    const blockCta = await createCtaEntry(environment, blockName, values.ctaText, values.ctaUrl);
    const sections = await getSections(environment, values);
    let blockEntry = await environment.createEntry("block", {
        fields: {
            name: {
                [LOCALES["English (US)"]]: blockName
            },
            type: {
                [LOCALES["English (US)"]]: values.type
            },
            images: {
                [LOCALES["English (US)"]]: imageEntries ? imageEntries.map(imageEntry => {return getLink(imageEntry)}) : null
            },
            title: {
                [LOCALES["English (US)"]]: createParagraphs(blockName)
            },
            description: {
                [LOCALES["English (US)"]]: createParagraphs(values.description)
            },
            cta: {
                [LOCALES["English (US)"]]: getLink(blockCta)
            },
            sections: {
                [LOCALES["English (US)"]]: sections ? sections.map(section => {return getLink(section)}) : null
            }
        }
    });
    return blockEntry;
}

const createCtaEntry = async (environment, blockName, ctaText, ctaUrl) => {
    const hasRequiredFields = ![ctaText, ctaUrl].includes("");
    if (!hasRequiredFields)
        return null;
    let entry = await environment.createEntry("cta", {
        fields: {
            name: {
                [LOCALES["English (US)"]]: `${blockName} CTA`
            },
            text: {
                [LOCALES["English (US)"]]: replaceEmptyWithNull(ctaText)
            },
            url: {
                [LOCALES["English (US)"]]: replaceEmptyWithNull(ctaUrl)
            },
            openInNewWindow: {
                [LOCALES["English (US)"]]: true
            }
        }
    })
    return entry;
}

const getSections = async (environment, values) => {
    let sections = [];
    const textComponentEntry = await createTextComponentEntry(environment, values.name, values.detailsHeader, values.detailsDescription)
    if (textComponentEntry)
        sections.push(textComponentEntry)
    const contactEntry = await createContactEntry(environment, values)
    if (contactEntry)
        sections.push(contactEntry)
    return sections;
}

const createTextComponentEntry = async (environment, blockName, title, description) => {
    const hasRequiredFields = ![title, description].includes("");
    if (!hasRequiredFields)
        return null;
    let entry = await environment.createEntry("textComponent", {
        fields: {
            name: {
                [LOCALES["English (US)"]]: `${blockName} Text Component`
            },
            title: {
                [LOCALES["English (US)"]]: createParagraphs(title)
            },
            description: {
                [LOCALES["English (US)"]]: createParagraphs(description)
            }
        }
    })
    return entry;
}

const createContactEntry = async (environment, values) => {
    const hasRequiredFields = values.url || values.address || values.phone || values.email
        || values.instagramUrl || values.twitterUrl || values.facebookUrl;
    if (!hasRequiredFields)
        return null;
    const blockName = values.name;
    const urlEntry = await createContactUrlEntry(environment, blockName, values.url)
    const locationEntry = await createContactLocationEntry(environment, blockName, values.address);

    let entry = await environment.createEntry("contact", {
        fields: {
            name: {
                [LOCALES["English (US)"]]: `${blockName} Contact`
            },
            phone: {
                [LOCALES["English (US)"]]: replaceEmptyWithNull(values.phone)
            },
            email: {
                [LOCALES["English (US)"]]: replaceEmptyWithNull(values.email)
            },
            url: {
                [LOCALES["English (US)"]]: getLink(urlEntry)
            },
            location: {
                [LOCALES["English (US)"]]: getLink(locationEntry)
            },
            instagramUrl: {
                [LOCALES["English (US)"]]: replaceEmptyWithNull(values.instagramUrl)
            },
            twitterUrl: {
                [LOCALES["English (US)"]]: replaceEmptyWithNull(values.twitterUrl)
            },
            facebookUrl: {
                [LOCALES["English (US)"]]: replaceEmptyWithNull(values.facebookUrl)
            }
        }
    })
    return entry;
}

const createContactUrlEntry = async (environment, blockName, url) => {
    const hasRequiredFields = url !== "";
    if (!hasRequiredFields)
        return null;
    return await createCtaEntry(environment, blockName, blockName, url);    
}

const createContactLocationEntry = async (environment, blockName, address) => {
    const hasRequiredFields = address !== "";
    if (!hasRequiredFields)
        return null;
    return await createTextComponentEntry(environment, `${blockName} Location`, blockName, address);
}

const createAssets = async (environment, files) => {
    let assets = [];
    for (const file of files) {
        let asset = await createAssetFromFile(environment, file);
        assets.push(asset);
    };
    return assets;
}

const createAssetFromFile = async (environment, file) => {
    const contentType = file.type
    const fileName = file.name
    let asset = await environment.createAssetFromFiles({
        fields: {
            title: {
                [LOCALES["English (US)"]]: fileName
            },
            file: {
                [LOCALES["English (US)"]]: {
                    contentType,
                    fileName,
                    file
                }
            }
        }
    })
    return await asset.processForLocale([LOCALES["English (US)"]], { processingCheckWait: 2000 });
}

const getLink = (entry) => {
    if (!entry)
        return null;
    return {
        "sys": {
            "type": "Link",
            "linkType": entry.sys.type === "Asset" ? entry.sys.type : "Entry",
            "id": entry.sys.id
        }
    };
}

const createParagraph = (text) => {
    return {
        "nodeType": "paragraph",
        "data": {},
        "content": [
            {
                "nodeType": "text",
                "data": {},
                "value": text,
                "marks": []
            }
        ]
    }
}

const createParagraphs = (text) => {
    return {
        "nodeType": "document",
        "data": {},
        "content": text.split("\n").map(line => {
            return createParagraph(line)
        })
    }
}

const replaceEmptyWithNull = (value) => {
    /*
    Reasons for using this function
    1. Not setting value of input elements to null because React says ""Warning: `value` prop on `input` should not be null."
    2. Contentful does not allow publishing entries where fields have validations, but the field
    value is "". Setting the field value to null skips the validation and allows publishing.
    */
    if (!value)
       return null;
    return value;
}