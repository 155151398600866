export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.sessionStorage.getItem("_user")

const setUser = user => {
  window.sessionStorage.setItem("_user", user)
}

export const handleLogin = ({ username, password }) => {
  const VENDOR_USERNAME = process.env.GATSBY_VENDOR_USERNAME;
  const VENDOR_PASSWORD = process.env.GATSBY_VENDOR_PASSWORD;
  if (username === VENDOR_USERNAME && password === VENDOR_PASSWORD) {
    setUser(username)
    return true;
  }
  return false
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user
}

export const logout = callback => {
  setUser("")
  callback()
}