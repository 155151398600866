import React from "react";
import "./banner.scss";
import ImageBlock from "../flimage";
import SafeHTML from "../safehtml";
import ButtonLink from "../flbuttonlink";
import MapLink from "../flmaplink";

export default function GenericBanner(props) {
    return (
        <>
            <section className={`${props.cssClass}`}>
                {props.image && <div className="banner-bg-el">
                    <ImageBlock image={props.image} addWrapper={false} addImageAsBg={false}></ImageBlock>
                </div>
                }
                <div className="banner-content">
                    <div className="container banner-container">
                        <div className="banner-inner">
                            {props.header && <div className="banner-title">
                                <SafeHTML>
                                    {props.header}
                                </SafeHTML>
                            </div>}

                            {props.subtext && props.subtext.title && <div className="banner-text">
                                <SafeHTML>
                                    {props.subtext.title}
                                </SafeHTML>
                            </div>}

                            {props.subtext && props.subtext.description && <div className="banner-text text-primary">
                                {
                                    props.DEVICE_VIEW.IsDesktop &&
                                        <SafeHTML>
                                            {props.subtext.description}
                                        </SafeHTML>
                                }
                                {
                                    !props.DEVICE_VIEW.IsDesktop && <MapLink {...props.subtext.description} />
                                }
                            </div>}

                            {props.cta &&
                                <ButtonLink className={`btn ${props.btnClass}`} {...props.cta} />
                            }
                            {props.children}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
