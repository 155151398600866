const isExternalUrl = (url) => {
    return (url && url.indexOf("http") > -1)
}
const getParameterByName = (name, url) => {
    if (typeof window === 'undefined')
        return url;
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const CarouselTypes = {
    "Hero Carousel": "Hero",
    "Card List Carousel": "Card"
}

const BannerTypes = {
    "Hero": "banner hero-banner",
    "Interior Hero": "banner interior-banner",
    "Card": "banner card-banner",
    "Error": "banner error-banner"
}

const BlockTypes = {
    "BiLateralStyle1": "BiLateral Style 1",
    "BiLateralStyle2": "BiLateral Style 2",
    "ListingCard": "Listing Card",
}

const BiLateralStyles = {
    [BlockTypes.BiLateralStyle1]: 'bilateral-style-1',
    [BlockTypes.BiLateralStyle2]: 'bilateral-style-2'
}

export {
    isExternalUrl,
    getParameterByName,
    CarouselTypes,
    BannerTypes,
    BlockTypes,
    BiLateralStyles,
};
