import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../BannersList/bannersgallery.scss";
import ImageBlock from "../flimage";


export default function ImagesGallery(props) {
    const getImages = () => {
        let images = props.block.images;
        if (images && images.length > 0) {
            return images.map(function (image, index) {
                return (
                    <div key={index} className="carousel-item">
                        <ImageBlock image={image} className="object-embed"></ImageBlock>
                    </div>
                );
            });
        } else return null;
    } 
    const getSliderSettings = () => {
        var slideToShow = 1;
        return {
            className: "carousel-items",
            slidesToShow: slideToShow,
            slidesToScroll: slideToShow,
            swipeToSlide: true,
            infinite: true,
            dots: true,
            autoplay: false,
            autoplaySpeed: 5000,
            arrows: false
        };
    };
    return (
        <div className="carousel-section">
            <div className="container">
                <Slider {...getSliderSettings()}>
                    {getImages()}
                </Slider>
            </div>
        </div>
    );

}
