import React from "react";
import GenericBanner from "./genericbanner";
import { BannerTypes } from "../../assets/js/utils"

export default function GenericBannerContainer(props) {
    let bannerProps = { ...props };
    const getBannerCSSClass = () => {
        let cssClass = BannerTypes[props.bannerStyle];
        return cssClass;
    }
    bannerProps.cssClass = getBannerCSSClass();
    switch (BannerTypes[props.bannerStyle]) {
        case BannerTypes.Hero:
            bannerProps.subtext && delete bannerProps.subtext.description;
            break;
        case BannerTypes.Card:
            bannerProps.btnClass = "btn-link";
            bannerProps.subtext && delete bannerProps.subtext.description;
            break;
        case BannerTypes.Error:
            bannerProps.subtext && delete bannerProps.subtext.description;
            break;
        default:
            break;
    }
    return (
        <GenericBanner {...bannerProps}></GenericBanner>
    );
};
