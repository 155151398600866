export const sendEmail = async (payload) => {
    try {
        const response = await fetch("/.netlify/functions/sendemail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        })
        console.log(response)
        if (!response.ok) return { success: false, message: response.statusText };
    
    } catch (e) {
        console.log(e);
    }
    return { success: true, message: "" };
}
