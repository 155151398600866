import React from "react";
import { Link } from "gatsby";
import { isExternalUrl } from "../assets/js/utils";


function ButtonComponent(props) {
  const { openInNewWindow, navigateToPage, url } = props;
  const HOME_PAGE_SLUG = "home";
  const linkUrl = navigateToPage && navigateToPage.slug ? (navigateToPage.slug === HOME_PAGE_SLUG ? "/" : (navigateToPage.slug).charAt(0) == "/" ? navigateToPage.slug : `/${navigateToPage.slug}`) : url;
  let isExternal = isExternalUrl(linkUrl);
  const targetAtt = openInNewWindow ? "_blank" : "_self";
  const className = props.className ? props.className : "btn";
  return <>
    {!isExternal && (
      <Link
        className={`${className}`}
        to={linkUrl}
        target={targetAtt}
      >
        {props.text}
      </Link>
    )}
    {isExternal && (
      <a
        className={`${className}`}
        href={linkUrl}
        target={targetAtt}
      >
        {props.text}
      </a>
    )}
  </>
}
export default function flButtonLink(props) {
  if (!props.text)
    return null;
  return (
    <>
      {props.addWrapper && <div className="container button-wrapper">
        <ButtonComponent {...props}></ButtonComponent>
      </div>}
      {!props.addWrapper &&
        <ButtonComponent {...props}></ButtonComponent>
      }
    </>
  );
}
